import React from "react"

import feature from "../images/feature.png"
import SectionHeader from "./section-header"

const Content = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="Calculate &amp; Track your pay"
      description="Perfect for freelancers, contractors and self employed workers."
    />
    <content
                       className="multiGrid"
    >
      <div style={{ textAlign: "left" }}>
        • Simple &amp; easy to use<br />
        • Completely free, no in-app purchases or hidden subscriptions!<br />
        • Track daily, weekly, monthly hours and pay earned<br />
        • See everything you are owed<br />
        • Add days off and breaks<br />
        • Add night shifts and 24 hour shifts<br />
        • A tipped worker mode: add estimated tips for every weekday<br />
        • Unlimited number of timesheets!<br />
        • Don't let your employer underpay you!<br /><br />
        Choose your hourly wage and currency. Set your working hours for each day of the week and instantly see your daily, weekly and monthly totals for hours worked and money earned. Add breaks and days off and they will be excluded from working hours calculations.

      </div>
      <div>
        <img src={feature} alt="a blank card floating over colorful graphics" className="logoImage" />
      </div>
    </content>
  </div>
)

export default Content
