import React from "react"

import SectionHeader from "./section-header"
import appStoreButton from "../images/app-store-button.png"

const CallToAction = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="Get the app"
      description=""
    />
    <a href="https://apps.apple.com/app/id1468530190">
    <img src={appStoreButton} style={{maxHeight: 60}}/>
    </a>
  </div>
)

export default CallToAction
